export const CasinoIconName = {
    CASINO_ALL: 'casino-all',
    CASINO_BACCARAT: 'casino-baccarat',
    CASINO_BETGAMES: 'casino-betgames',
    CASINO_BLACKJACK: 'casino-blackjack',
    CASINO_BONUSBUY: 'casino-bonusbuy',
    CASINO_BOOKS: 'casino-books',
    CASINO_CHERRY: 'casino-cherry',
    CASINO_DEALER: 'casino-dealer',
    CASINO_DIAMOND: 'casino-diamond',
    CASINO_EASTER: 'casino-easter',
    CASINO_EXCLUSIVE: 'casino-exclusive',
    CASINO_FAVORITES: 'casino-favorites',
    CASINO_HALLOWEEN: 'casino-halloween',
    CASINO_HOME: 'casino-home',
    CASINO_INDIAN: 'casino-indian',
    CASINO_JACKPOT: 'casino-jackpot',
    CASINO_LOTTERY: 'casino-lottery',
    CASINO_LOBBY: 'casino-lobby',
    CASINO_MEGAWAYS: 'casino-megaways',
    CASINO_MONEYWHEEL: 'casino-moneywheel',
    CASINO_NEW: 'casino-new',
    CASINO_NEW_CHIP: 'casino-new-chip',
    CASINO_NEWYEAR: 'casino-newyear',
    CASINO_OTHER: 'casino-other',
    CASINO_POKER: 'casino-poker',
    CASINO_ROULETTE: 'casino-roulette',
    CASINO_SCRATCH: 'casino-scratch',
    CASINO_SEVENS: 'casino-sevens',
    CASINO_SHOW: 'casino-show',
    CASINO_SLOTS: 'casino-slots',
    CASINO_SPORT: 'casino-sport',
    CASINO_TOP: 'casino-top',
    CASINO_TV: 'casino-tv',
    CASINO_GAME_AVIATOR: 'casino-game-aviator',
    CASINO_MY_GAMES: 'casino-my-games'
};
// eslint-disable-next-line @typescript-eslint/no-redeclare
const casinoIconNames = Object.values(CasinoIconName);
export function isCasinoIconName(value) {
    return casinoIconNames.includes(value);
}
