import { GqlBatchedClient, ApiConnectionError, ApiRequestAbortedError } from '@leon-hub/api';
import { logger } from '@leon-hub/logging';
let ApplicationGqlClient = class ApplicationGqlClient extends GqlBatchedClient {
    async requestGraphql(query, param, callback, requestOptions, ref) {
        let { options } = param;
        try {
            return await super.requestGraphql(query, {
                options
            }, callback, requestOptions, ref);
        } catch (error) {
            if ((error instanceof ApiConnectionError || error instanceof ApiRequestAbortedError) && !error.silent && this.connectionErrorResolver) try {
                logger.debug(`Connection error GraphQL: ${query}`);
                await this.connectionErrorResolver(error);
                return this.requestGraphql(query, {
                    options
                }, callback, requestOptions, ref);
            } catch  {}
            throw error;
        }
    }
};
export { ApplicationGqlClient as default };
