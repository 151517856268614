export function useApiMethod(client, method, // TODO: Fix dep cycle for ApiMethod
// @ts-ignore
middleware) {
    // TODO: Fix dep cycle for ApiMethod
    // @ts-ignore
    const stop = client.addMiddleware(method, middleware);
    return {
        stop
    };
}
