import { updateEnvironment } from '@leon-hub/environment-client';
import { AppError } from '@leon-hub/app-errors';
import getDefinedEnvironment from './getDefinedEnvironment';
import checkEnvironmentIsClean from './checkEnvironmentIsClean';
function preventChange(key) {
    const error = new AppError({
        message: `EnvironmentPlugin has not been initialized, unable to get/access process.env['${String(key)}']`
    });
    // eslint-disable-next-line no-console
    console.error(error);
}
export const bootstrapEnvironment = ()=>{
    "1";
    checkEnvironmentIsClean(process.env);
    const rawProcessEnvironment = {
        ...process.env,
        ...getDefinedEnvironment()
    };
    const environment = {
        ...rawProcessEnvironment
    };
    updateEnvironment({
        environment
    });
    const rawKeys = Object.keys(rawProcessEnvironment);
    function isUpdateAllowed(key) {
        if (rawKeys.includes(String(key))) {
            preventChange(key);
            return false;
        }
        return true;
    }
    if ('test' === rawProcessEnvironment.NODE_ENV) // @ts-ignore
    process.env = rawProcessEnvironment;
    else {
        const proxy = new Proxy(process.env, {
            get (target, rawKey) {
                return environment[String(rawKey)];
            },
            deleteProperty (target, key) {
                if (isUpdateAllowed(key)) {
                    delete environment[String(key)];
                    return true;
                }
                return false;
            },
            set (target, key, value) {
                if (isUpdateAllowed(key)) {
                    Object.defineProperty(environment, String(key), {
                        value,
                        configurable: true
                    });
                    return true;
                }
                return false;
            }
        });
        process.env = proxy;
    }
};
