import { isError } from '@leon-hub/guards';
import { getErrorMessage } from './getErrorMessage';
const defaultMessage = 'Unexpected state';
export default function normalizeError(exception) {
    let message = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : defaultMessage;
    if (isError(exception)) return exception;
    const errorFallback = new Error(getErrorMessage(exception, message));
    Error.captureStackTrace?.(errorFallback, normalizeError);
    return errorFallback;
}
