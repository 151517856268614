/* eslint-disable @typescript-eslint/no-duplicate-enum-values */ 


 export const EditionValueProductLi = "li";
export const EditionValueProductLeonru = "leonru";
export const EditionValueProductSunny = "sunny";
export const EditionValueProductSlott = "slott";
// Rendering constants
export const EditionValueRenderingCsr = "csr";
export const EditionValueRenderingSsr = "ssr";
export const EditionValueRenderingPrerender = "prerender";
// Platform constants
export const EditionValuePlatformWeb = "web";
export const EditionValuePlatformPwa = "pwa";
export const EditionValuePlatformCordova = "cordova";
export const EditionValuePlatformNative = "native";
// Modernity constants
export const EditionValueModernityLegacy = "legacy";
export const EditionValueModernityModern = "modern";
// Environment constants
export const EditionValueEnvDev = "dev";
export const EditionValueEnvProd = "prod";
// Skin Family constants
export const EditionValueSkinFamilyDefault = "default";
export const EditionValueSkinFamilyCasino = "casino";
// Skin constants
export const EditionValueSkinDefault = "default";
export const EditionValueSkinDefaultAso = "defaultaso";
export const EditionValueSkinLeoncasino = "leoncasino";
export const EditionValueSkinLeoncasinoAso = "leoncasinoaso";
export const EditionValueSkinLeonlivede = "leonlivede";
export const EditionValueSkinLeoncasino2 = "leoncasino2";
export const EditionValueSkinLeoncasinoonly = "leoncasinoonly";
export const EditionValueSkinLeoncasinobr = "leoncasinobr";
export const EditionValueSkinTwin = "twin";
export const EditionValueSkinTwinAso = "twinaso";
export const EditionValueSkinSlott = "slott";
export const EditionValueSkinSlottAso = "slottaso";
export const EditionValueSkinSunny = "sunny";
export const EditionValueSkinCustom1 = "custom1";
export const EditionValueSkinCustom2 = "custom2";
// Style constants
export const EditionValueStyleLeon = "leon";
export const EditionValueStyleTwin = "twin";
export const EditionValueStyleSlott = "slott";
// OS constants
export const EditionValueOsAndroid = "android";
export const EditionValueOsIos = "ios";
export const EditionValueOsMacos = "macos";
export const EditionValueOsWindows = "windows";
export const EditionValueOsLinux = "linux";
export const EditionValueOsOther = "other";
// Layout constants
export const EditionValueLayoutPhone = "phone";
export const EditionValueLayoutTablet = "tablet";
export const EditionValueLayoutDesktop = "desktop";
// Browser constants
export const EditionValueBrowserChrome = "chrome";
export const EditionValueBrowserEdge = "edge";
export const EditionValueBrowserSafari = "safari";
export const EditionValueBrowserFirefox = "firefox";
export const EditionValueBrowserYandex = "yandex";
export const EditionValueBrowserOpera = "opera";
export const EditionValueBrowserUnsupported = "unsupported";
export const EditionValueBrowserNo = "no";
// Theme constants
export const EditionValueThemeDark = "dark";
export const EditionValueThemeLight = "light";
// Bundler constants
export const EditionValueBundlerWebpack = "webpack";
export const EditionValueBundlerVite = "vite";
